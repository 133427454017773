@import url(https://fonts.googleapis.com/css?family=Poppins:light,regular,semi-bold,bold);
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* unvisited link */
a:link {
  color: #9bb52a;
}

/* visited link */
a:visited {
  color: #57702e;
}

/* Text Input Placeholder style */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  font-weight: 550;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  font-weight: 550;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
  font-weight: 550;
}

